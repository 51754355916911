.menu-item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    color: black;
    line-height: 2.5rem;
}

.menu-item--clickable {
    color: var(--color-primary-dark);
}

.menu-item--clickable:hover {
    cursor: pointer;
    color: #fcac04;
}

.menu-item--disabled {
    color: #CDD8C9
}

.menu-item__label {
    line-height: 2.5rem;
    font-size: 1rem;
}

.menu-item__label--sub-item {
    padding-left: max(1rem, 2vw);
}

.menu-item__arrow {
    font-size: 0.9rem;
    padding-right: 1rem;
    color: inherit;
}
