.BalanceMenuBuy {
    margin-top: 1rem;

    &__label {
        margin-bottom: 0.2rem;
        margin-top: 1rem;
    }

    &__currency {
        padding-top: 0.2rem;
        padding-bottom: 0.5rem;
    }

    &__currency svg {
        width: 3rem;
        text-align: center;
        padding-bottom: 0;
        margin-bottom: 0;
        display: block;
        margin: 0 auto;
    }

    &__currency span {
        display: block;
        margin: 0;
        padding: 0;
        line-height: 1rem;
    }

    &__amount {
        text-align: center;
        font-size: 1.6rem;
        font-weight: 600;
    }
}