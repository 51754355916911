.service_start__loader {
    padding-top: 0.3rem;
    display: flex;
    align-items: center;
    width: 100%;
}

.service_start__loader-text {
    margin-left: .7rem;

}