.poi__poi {
    padding-top: 1rem;
    padding-left: 1rem;
}

.poi__poiServices {
    margin-top: 2rem;
}

.poi__poiServiceIcons {
    float: right;
}

.payment_top_container,
.accessibility_top_container,
.availability_container_poi {
    display: flex;
    line-height: 50px;
}

.accessibility_top_container h2 {
    margin-bottom: 0;
}

.payment_top_container_hide_link,
.accessibility_top_container_hide_link {
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.arrow_down {
    content: "<";
    rotate: 90deg;
}

.availability_container_poi_left {
    flex: 1;
}

.availability_container_poi_left h2,
.availability_container_poi_left p {
    margin-bottom: 0;
}

.availability_container_poi_right {
    float: right;
    font-size: 5vh;
    display: flex;
}

.poi__accessability,
.poi_payment,
.poi_activation,
.poi_priceinfo {
    display: flex;
    line-height: 50px;
}

.poi__accessability_item,
.poi__payment_item,
.poi__activation_item,
.poi_priceinfo_item {
    flex: 1;
    align-self: center;
}

.poi_payment .anticon svg,
.poi_activation .anticon svg {
    height: 0.7em;
}

.poi__wrapper {
    overflow-y: auto;
    height: 81.5vh;
}

.favorite_icon {
    color: #FCAC04;
    align-self: flex-start;
}

.anticon {
    color: black;
}

.price_info_area {
    padding: 0;
}

.price_info_until_time_container,
.price_info_until_price_container {
    display: inline-block;
}

.price_info_until_time_input {
    font-size: 16px;
    width: 50px;
    text-align: center;
}

.price_info_until_price_input {
    font-size: 16px;
    width: 100px;
    text-align: center;
}

.price_info_until_time_span {
    font-size: 16px;
    font-weight: bold;
    margin: 0 5px 0 5px;
}

.until_price_span {
    font-size: 16px;
    margin-left: 5px;
}

.poi__poi h2 {
    font-size: 1rem;
}

.menu-item-container {
    margin-bottom: 0;
}

.menu-item-container h2 {
    margin-bottom: 0;
}

.show_selected_area {
    display: block;
}

.hide_selected_area {
    display: none;
    color: #414042;
}

.poi__actions {
    margin-top: 2rem;
}