.menu-selectable-icon {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.menu-selectable-icon__icon {
    color: #FCAC04;
    min-width: 60px;
    text-align: center;
    display: block;
}
