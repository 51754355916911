.settings {
    height: 81.5vh;
    max-width: 40rem;
    width: 87vw;
    z-index: 2;
    position: absolute;
    top: 6.5vh;
    left: 0;
    background-color: white;
    border-top: 1px #e9eee8 solid;
    border-bottom: 1px #e9eee8 solid;
    padding-right: 0.5rem;
}

.settings__auth {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 90%;
}

.settings__user-section {
    height: 100%;
}

.info__join-us {
    color: black;
    font-size: 6vh;
    text-align: center;
}

.info__registration {
    color: black;
    font-size: 0.9rem;
    text-align: center;
}

.auth__authentificator {
    height: 100%;
    overflow-y: auto;
}