.service-detail {
    display: flex;
    flex-flow: column nowrap;
}

.service-detail__progress_indicator {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    padding: 7px 0 4px 0;
    border: 1px solid #CDD8C9;
    border-radius: 1rem;
}

.service-detail__startstop-button {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    
}

.service-detail__startstop-button__button {
    border: 1px solid #CDD8C9;
    background-color: #fcac04;
    display: flex;
    flex-flow: nowrap;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 1rem;
    width: 100%;
    padding: 0;
    cursor: pointer;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
}

.service-detail__startstop-button__button-stop {
    background-color: #DE5A43;
}

.service-detail__startstop-button__text {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1rem;
    margin-right: 1rem;
}

.service-detail__consumption {
    margin: 10px 0;
    text-align: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.service-detail__consumption-title__text {
    font-weight: bold;
    color: silver;
}

.service-detail__consumption-amount__text {
    font-size: 1.5vh;
}

.service-detail__service-type {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.service-detail__properties {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.service-detail__rating {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transform: translateX(0.8rem);
}

.rating__stars {
    color: #FDCF74 !important;
    font-size: 1rem;
}

.rating__votes {
    color: #CDD8CA;
    padding-left: .5rem;
    font-size: 0.7rem;
    transform: translateY(-0.2rem);
}

.service-type__icon {
    font-size: 2rem;
}

p.properties__service-name {
    font-weight: bold;
    margin: 1.2rem 0 1.5rem 0;
    text-align: center;
    max-width: 10rem;
}

.service-detail__properties-status {
    margin-bottom: 1rem;
    text-align: center;
}

.properties__service-price {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding-bottom: 1em;
}