.menu-selectable {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    text-align: center;
    align-items: flex-start;
    margin-bottom: 0.5rem;
    overflow: auto;
    padding-top: .5rem;
}

.menu-selectable--sub-item {
    padding-left: 2rem;
    line-height: normal;
}

.menu-selectable--centered {
    padding-left: 0;
    justify-content: center;
}

.menu-selectable--bigger-top-margin {
    margin-top: .5rem;
}

.menu-selectable--no-bottom-margin {
    margin-bottom: 0;
}
