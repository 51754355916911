.menu-input--sub-item {
    padding-left: max(1rem, 2vw);
    font-size: 0.9rem;
}

.menu-input__input{
    padding-left: 0;
    font-weight: 500;
}
.menu-input__input:placeholder-shown {
    font-weight: 400;
    font-style: italic;
}
