.user-info__box {
    padding: 1rem 0 0 1rem;
    height: 100%;
    overflow-y: auto;

}

.user-info {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 16.5vh;
}

.userinfo__username {
    color: black;
    font-size: 3vh;
    margin-bottom: 0;
}

.userinfo__currency {
    color: black;
    font-size: 2vh;
    margin-bottom: 0;
}

.user-info__photo-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: 90%;
}

.photo-container__photo{
    font-size: 10vh;
    padding-left: 0rem;
}

.user-info__details {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 90%;
}

.userinfo__balance {
    text-align: left;
    width: 100%;
}

.userinfo__menu {
    padding-left: 1rem;
    padding-right: max(1rem, 2vw);
    padding-bottom: 1rem;
}