.menu-slider {
    margin-bottom: 1rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.menu-slider__slider {
    width: 100%;
}

.menu-slider__button, .menu-slider__button:focus, .menu-slider__button:hover {
    color: #fcac04;
    background-color: white;
    border-color: transparent;
    height: fit-content;
}

.menu-slider__button[disabled], .menu-slider__button[disabled]:hover {
    color: #CDD8C9;
    background-color: white;
    border-color: transparent;
    height: fit-content;
}

.menu-slider__button__text {
    font-size: 2rem;
    margin: 0;
}

.menu-slider--sub-item {
    padding-left: 5vw;
}
