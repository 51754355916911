@import '~leaflet/dist/leaflet.css';

.map {
    height: 81.5vh;
    width: 100%;
}

.map__map-container {
    height: 81.5vh;
    width: 100%;
    z-index: 1;
    position: absolute;
    top: 6.5vh;
    left: 0;
}

.leaflet-div-icon {
    background: transparent;
    border: none;
}

/* to prevent flickering on compoment re-rendering */
.leaflet-fade-anim .leaflet-popup,
.leaflet-fade-anim .leaflet-popup .ant-btn,
.leaflet-fade-anim .leaflet-popup .ant-rate-star,
.leaflet-fade-anim .leaflet-popup .ant-rate-star-first,
.leaflet-fade-anim .leaflet-popup .ant-rate-star-second
{
    transition: none;
    -moz-transition: none;
    -webkit-transition: none;
}