.POIMenuSelected__props {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: .4rem;
    margin-bottom: 0.2rem;

    &_description {
        grid-column: 1 / 3;
        font-size: 1rem;
        margin: 0;
        padding-top: 0.5rem;
        padding-bottom: 0.4rem;
        font-weight: 600;
    }

    &_label {
        font-weight: 400;
        color: var(--color-dark-grey);
    }

    &_item {
        padding: 0;
        margin: 0;
    }

    &_services_label {
        grid-column: 1 / 3;
        padding-top: 1rem;
        font-size: 1.2rem;
    }

    &_services {
        grid-column: 1 / 3;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    &_link {
        cursor: pointer;
        list-style: none;
        display: flex;
        justify-content: space-between;
        padding-left: 2rem;
        color: var(--color-primary-dark);
        padding-bottom: 0.5rem;
    }
    &_link:hover {
        color: var(--color-primary);
    }
}