@import '../../../../styles/variables.scss';

.addpoi {
    &__map {
        min-height: 200px;
        border: 1px dotted $color-light-grey;
    }

    &__label {
        display: block;
        padding: 0;
        line-height: 1rem;
        padding-top: 1rem;
        padding-bottom: 0.4rem;
        font-size: 1rem;
    }
}