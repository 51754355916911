.session__box {
    border-top: 1px dotted #fcac04;
    margin-top: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
}

.session__button {
    border: 1px solid #CDD8C9;
    background-color: #fcac04;
    color: white;
    padding: 0;
    cursor: pointer;
    border-radius: 10px;
    width: 100px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.session__button_error_text {
    color: red;
    display: block;
    margin-bottom: 0px;
}

.session__actions {
    display: flex;
    align-items: end;
    margin-top: 0.5rem;
}