.menu {
    height: 6.5vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 6vh
}

.menu--top {
    font-size: 5vh;
    justify-content: flex-end;
    border-bottom: 1px #e9eee8 solid;
    flex: 0 0 50%;
}

.menu--bottom {
    height: 11vh;
    border-top: 1px #e9eee8 solid;
}

.menu__icon {
    font-size: 8vh;
}

.menu--horizontal {
    flex-flow: row nowrap;
}

.menu--vertical {
    flex-flow: column nowrap;
    height: 60vh;
    width: calc(5vh + 6vw);
    z-index: 2;
    position: absolute;
    top: 6.5vh;
    right: 0;
    background-color: white;
}

.menu--half-flex {
    flex: 0 0 50%;
}
