.session__wrapper {
    padding: 1rem 1rem 1rem 2rem;
    height: 100%;
    overflow: auto;
}

.session__header {
    padding-top: 1rem;
}

.session__historybox {
    
    display: flex;
    justify-content: flex-end;
    
}