html {
    font-size: max(14px, 1.7vh);
}

/** apple require input elements to be at least 16px, otherwise it will zoom in */
html body div.settings input,
html body div.settings textarea,
html body div.settings select {
    font-size: max(16px, 1.7vh);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --color-primary: #fcac04;
    --color-primary-light: #facd6c;
    --color-primary-dark: #795200;
    --color-light-grey: silver;
    --color-dark-grey: #666;
    --color-disabled: #f5f5f5;
    --color-disabled-border: #d9d9d9;
    --color-disabled-text: rgba(0,0,0,.25);
}

html div.settings input,
html div.settings textarea,
html div.settings select {
    font-size: max(16px, 1.7vh);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.amplify-loader {
    --amplify-components-loader-stroke-filled: orange;
}

h3 {
    padding: 1rem 0 0 0;
}