.link__icon {
    font-size: 6vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-link-icon__link{
    height: fit-content;
}

.menu-link-icon{
    height: fit-content;
}
