.poiService__box {
    border-bottom: 1px dotted #fcac04;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
}

.poiService__box__name {
    padding-top: 0rem;
    padding-bottom: 1rem;
}

.poiService__header {
    margin: 0;
    padding: 0.5rem 0 0 0;
}

/** wrapper around button */
.poiService__serviceButtons {
    display: flex;
    justify-content: right;
}

.poiService__button {
    border: 1px solid #CDD8C9;
    background-color: #fcac04;
    color: white;
    padding: 0;
    cursor: pointer;
    border-radius: 10px;
    width: 100px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.poiService__button_loading {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding-top: 5px;
}

.poiService__button_error_text {
    color: red;
    text-align: right;
    display: block;
    margin-bottom: 0px;
}
